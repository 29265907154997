/**
 * Mixins docs:
 * https://es.vuejs.org/v2/guide/mixins.html
 * https://codingpotions.com/vue-mixins
 */
import * as moment from 'moment'

export const mixinAlerts = {
  methods: {
    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast([message], {
        title,
        variant,
        solid: true,
      })
    },
    formatDate(date = null, type = null) {
      return moment(date).format(type)
    },
  },
}

export const _ = null
